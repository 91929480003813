
































































































.two-auth-box {
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 7px 10px 2px #00000038;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 1.25rem;
    background-color: #009750;
    border-radius: 10px 10px 10px 10px;
    .text {
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
    }
    .icon {
      cursor: pointer;
      img {
        height: 20px;
        width: auto;
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .content-box {
    padding: 1.25rem;
    img {
      max-width: 100%;
    }
    .form-group {
      margin-bottom: 20px;
      label {
        font-size: 17px;
        color: #6c757d;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .auth-copy {
        margin-top: 5px;
        background: #009750;
        border-radius: 5px;
        .input-group-text {
          padding: 0;
          border: 0;
          button {
            width: 70px;
            height: 40px;
            padding: 0;
            background: #8dc63f;
            border: 0;
            outline: none;
            border-radius: 5px;
            box-shadow: 0px 0px 5px 1px #0000009e;
            font-weight: 600;
          }
        }
        input {
          height: 40px;
          border: 0;
          background: transparent;
          color: #fff;
          font-weight: 600;
          &::placeholder {
            color: #fff;
            opacity: 0.8;
          }
          &:hover,
          &:focus,
          &:focus-visible {
            outline: none;
            box-shadow: none;
            border: 0;
          }
        }
      }
    }
  }
}
